@use '@angular/material' as mat;

@include mat.core();

$brand-palette: (
  50: #fff0ea,
  100: #ffd9cb,
  200: #ffbfa8,
  300: #ffa585,
  400: #ff926a,
  500: #ff7f50,
  600: #ff7749,
  700: #ff6c40,
  800: #ff6237,
  900: #ff4f27,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffdbd4,
  A700: #ffc6bb,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$my-primary: mat.define-palette($brand-palette, 500);
$my-accent: mat.define-palette(mat.$grey-palette, A200, A100, A400);
$custom-typography: mat.define-typography-config(
  $font-family: 'Quicksand, sans-serif',
);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    typography: $custom-typography,
    density: 0,
  )
);

@include mat.all-component-themes($my-theme);

body {
  .mdc-linear-progress {
    height: 15px;
    border-radius: 12px;
  }
  .mdc-menu-surface {
    border-radius: 5px;
    background: #fff;
    box-shadow: 12px 12px 40px 0px rgba(0, 0, 0, 0.1);
  }
  .mat-calendar {
    font-family: 'Quicksand';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .mat-calendar-body-selected {
    background: #feefe5;
    color: #ff9150;
  }
  .mat-datepicker-content {
    border-radius: 5px;
    box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.1);
  }
  .mat-calendar-body-cell-content {
    color: #252525;
  }
  .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: none;
  }
  .mat-calendar-body-label {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    visibility: hidden;
  }
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    background: #feefe5;
  }
  .mat-calendar-period-button {
    padding: 12px 16px;
    &:hover {
      background: #feefe5;
    }
  }
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    border-color: transparent;
  }
  .mat-calendar-body-today::after {
    content: '\2022';
    display: block;
    text-align: center;
    position: absolute;
    margin-top: 20px;
    font-size: 1rem;
    color: #ff9150;
  }
  .mat-calendar-table-header {
    color: #ff9150;

    span[aria-hidden='true'] {
      font-size: 0; // Set font size to 0 to hide all text
      display: inline-block; // Set display to inline-block for proper ::first-letter styling
      overflow: hidden;

      &::first-letter {
        font-size: 14px; // Set font size of the first letter
        text-transform: uppercase; // Capitalize the first letter
      }
    }
  }
  .mat-calendar-table-header-divider {
    display: none;
  }
  .time-container:before {
    display: none;
  }
  .time-container {
    padding: 8px 16px;
    border-radius: 5px;
    justify-content: flex-start;
    margin: 0px 32px 32px 32px;
    background: #f8f8f8;
    .mat-mdc-icon-button {
      display: none;
    }
    .ngx-mat-timepicker mat-form-field .mdc-line-ripple {
      display: none;
    }
    tbody {
      display: flex;
      align-items: center;
    }

    .ngx-mat-timepicker-table mat-form-field:first-child .mat-mdc-text-field-wrapper {
      transform: translateY(-2px);
    }

    .ngx-mat-timepicker-table mat-form-field:last-child .mat-mdc-text-field-wrapper {
      transform: translateY(2px);
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
    table td:empty {
      display: none;
    }
    .ngx-mat-timepicker
      form
      .ngx-mat-timepicker-table
      .ngx-mat-timepicker-tbody
      tr
      td
      .mat-mdc-form-field
      .mdc-text-field--filled
      .mat-mdc-form-field-infix
      input {
      font-size: 14px;
      font-weight: 500;
    }
    .ngx-mat-timepicker
      form
      .ngx-mat-timepicker-table
      .ngx-mat-timepicker-tbody
      tr
      td
      .mat-mdc-form-field
      .mdc-text-field--filled
      .mat-mdc-form-field-infix {
      font-size: 14px;
      padding: 0;
    }
    .ngx-mat-timepicker
      form
      .ngx-mat-timepicker-table
      .ngx-mat-timepicker-tbody
      tr
      td.ngx-mat-timepicker-spacer {
      font-weight: 500;
      transform: translateX(-3px) translateY(1px);
    }
    ngx-mat-timepicker [formcontrolname='minute'] {
      transform: translateX(-4px);
    }
    .ngx-mat-timepicker-tbody {
      transform: translateY(-2px);
    }
  }
  button.mat-calendar-period-button > .mdc-button__label > span[aria-hidden='true'] {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
  }
  .mat-datepicker-content .mat-calendar {
    height: auto;
  }

  .mat-datepicker-input {
    &:focus-within {
      box-shadow: none;
    }
  }
  .mat-calendar-header {
    padding: 20px 32px 16px;
  }
  .mat-calendar-content {
    padding: 0px 32px 16px 32px;
  }

  mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
    width: 100%;
    border-radius: 5px;
    border: none;

    .mat-button-toggle {
      flex: 1;
      border: none;
      background: #fff6f1;
      transition: color 0.07s ease-in-out;

      .mat-button-toggle-label-content {
        line-height: 36px;
      }

      &:hover {
        &:not(.mat-button-toggle-checked) {
          background: #ffe6d7;
          border-radius: 5px;
        }

        .mat-button-toggle-focus-overlay {
          opacity: 0;
        }
      }
    }

    .mat-button-toggle-checked {
      color: white;
      border-radius: 5px;
      background: var(--gradients-orange);
    }

    .mat-button-toggle + .mat-button-toggle {
      border-left: none;
    }

    .mat-button-toggle-focus-overlay,
    .mat-button-focus-overlay {
      background-color: red !important;
    }

    .mat-ripple-element {
      display: none;
    }
  }

  .mat-expansion-panel {
    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }

    &.mat-expanded {
      overflow: visible;
    }

    .mat-expansion-panel-header {
      padding: 0;
      font-size: 20px;
      font-weight: 700;
      height: 24px;

      .mat-expansion-indicator {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #878787;
        font-size: 15px;
      }
    }
    .mat-expansion-panel-content {
      font: inherit;
      letter-spacing: inherit;
    }
    .mat-expansion-panel-body {
      margin-top: 16px;
      padding: 0;
    }
  }

  .add-new-page-container {
    .mat-expansion-panel {
      background: var(--gray-very-light);
    }
  }

  .mat-expansion-panel
    .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
  .mat-expansion-panel
    .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
  .mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
    background: none;
  }

  .mat-button-toggle {
    font-family: 'Quicksand';
  }

  .draggable-menu-element {
    transition: transform 100ms ease-in-out;
    &.cdk-drag-preview {
      border-radius: 5px;
      background: white;
      box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.05);
    }
  }

  .page-card {
    &.cdk-drag-preview {
      width: 100%;
      border-radius: 5px;
      background: white;
      box-shadow: 15px 15px 60px 0px rgba(0, 0, 0, 0.2);

      fa-icon {
        color: var(--orange);
      }
    }
  }

  .page-title {
    transition: transform 100ms ease-in-out;

    &.cdk-drag-preview {
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 5px;
      background: white;
      box-shadow: 15px 15px 60px 0px rgba(0, 0, 0, 0.2);

      fa-icon {
        color: var(--orange);
      }

      .edit-pen,
      .delete-page-button,
      .grip-icon {
        display: none;
      }
    }
  }

  .drag-placeholder {
    height: 2px;
    background-color: var(--orange);
    position: relative;
  }

  .drag-placeholder::before,
  .drag-placeholder::after {
    content: '';
    position: absolute;
    top: -4px;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .drag-placeholder::before {
    left: 0;
    border-width: 5px 0 5px 10px;
    border-color: transparent transparent transparent var(--orange);
  }

  .drag-placeholder::after {
    right: 0;
    border-width: 5px 10px 5px 0;
    border-color: transparent var(--orange) transparent transparent;
  }

  rk-add-new-page {
    .drag-placeholder {
      height: 40px;
      width: 168px;
      background-color: white;
      border-radius: 5px;
    }

    .drag-placeholder::before,
    .drag-placeholder::after {
      display: none;
    }
  }

  // slider
  .mat-mdc-slider {
    width: 100%;
    height: 12px;

    .mdc-slider__track--inactive {
      border-color: #fff2ed;
      background-color: #fff2ed;
      opacity: 1;
    }

    .mdc-slider__track--active_fill {
      border-color: #fff2ed;
      background-color: #fff2ed;
    }

    .mdc-slider__thumb {
      height: 12px;
      width: 12px;
      left: -8px;

      .mdc-slider__thumb-knob {
        box-shadow: none;
        width: 8px;
        height: 8px;
        border-width: 0px;
      }
    }

    .mat-mdc-focus-indicator {
      border-radius: 50%;
    }
  }
}

body rk-plan .mat-button-toggle-group {
  border: 1px solid white;
  border-radius: 0.25rem;
  font-weight: 400;
  width: fit-content;

  .mat-button-toggle {
    font-size: 1.125rem;
    background: transparent;
    color: #fff;
    line-height: 49px;
    border-radius: 0;
    padding: 0 12px;

    &:hover {
      background: transparent !important;
      opacity: 0.8;
    }

    &.mat-button-toggle-checked {
      color: var(--orange);
      background: #fff;
      font-weight: 400;

      &:hover {
        background: #fff !important;
        opacity: 0.8;
      }

      .tag {
        background: var(--gradients-orange);
        color: #fff;
      }
    }
  }
}

.congrats-backdrop {
  background: rgba(0, 0, 0, 0.75);
}
